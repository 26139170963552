<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body" :style="intraction">
        <!-- BOC:[error] -->
        <ApiErrorDialog v-if="api.isError" :api="api" />
        <ApiErrorDialog v-if="avatarSaveApi.isError" :api="avatarSaveApi" />
        <ApiErrorDialog v-if="buyApi.isError" :api="buyApi" />
        <!-- EOC -->
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("title.shop") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" id="TutExit" />
          </div>
          <div>
            <div class="shop-panel">
              <v-img
                :src="require('@/assets/explore/menu/wardrobe.png')"
                max-width="80"
                class="ms-2 d-flex justify-center align-end text-center text-stroke-md"
                style="cursor: pointer;"
                @click="visitWardrobe()"
              >
              {{$t("title.wardrobe")}}
              </v-img>
            </div>
            <v-img
              class="d-flex align-center px-5 mx-auto"
              :src="require('@/assets/explore/shop.png')"
              contain
            >
              <AbstractAvatar
                :avatar="studentAvatar"
                :height="200"
                :width="200"
                class="mx-12"
                :selectedItem="selectedItem"
              ></AbstractAvatar>
            </v-img>
            <div style="height: 50px; margin-top: -50px; margin-bottom: 20px">
              <div
                class="pa-2 text-center"
                v-if="
                  selectedItemInventory && selectedItemInventory.quantity == 0
                "
              >
                <v-btn
                  :loading="buyApi.isLoading"
                  large
                  color="yellow"
                  class="text-h5 px-8"
                  @click="buyConfirm"
                  id="BuyNow"
                >
                  {{ $t("action.buy") }}
                  <!-- <v-img
                      max-width="20"
                      max-height="20"
                      class="mx-1"
                      :src="require('@/assets/island/MochiCoin.png')"
                    >
                    </v-img>
                    {{ selectedItem.price }} -->
                </v-btn>
              </div>
              <div
                class="pa-2 text-center"
                v-else-if="
                  selectedItemInventory && selectedItemInventory.quantity > 0
                "
              >
                <v-chip
                  class="text-h5 px-8 grey--text rounded-sm text-uppercase"
                >
                  {{ $t("string.own") }}
                </v-chip>
              </div>
            </div>
            <v-carousel v-model="selectedTab" hide-delimiters height="60">
              <template v-slot:prev="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-img
                    contain
                    max-width="40"
                    class="mx-auto"
                    :src="require('@/assets/island/ArrowButtonPre.png')"
                  ></v-img>
                </v-btn>
              </template>
              <template v-slot:next="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-img
                    contain
                    max-width="40"
                    class="mx-auto"
                    :src="require('@/assets/island/ArrowButtonNex.png')"
                  ></v-img>
                </v-btn>
              </template>
              <v-carousel-item v-for="(nav, i) in navs" :key="i" :value="nav">
                <v-sheet tile class="tab-bar-card" height="100%">
                  <div class="text-h6 tab-card">{{ nav.name }}</div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>

            <div class="items-panel pa-3 mt-2">
              <div class="coin-wrapper">
                <div v-if="selectedTab.key == 'event' && items.length > 0">
                  <div
                    class="text-center brown--text"
                    v-if="eventStatus == 'ONGOING'"
                  >
                    {{ $t("string.closeIn") }} {{ countdown }}
                  </div>
                  <div
                    class="text-center brown--text"
                    v-else-if="eventStatus == 'UPCOMING'"
                  >
                    {{ $t("string.openIn") }} {{ countdown }}
                  </div>
                </div>
                <div
                  v-if="
                    selectedTab.key == 'monthly' &&
                    items.length > 0 &&
                    items[0].Item.ShopItem[0].timestampEnd
                  "
                >
                  <div class="text-center brown--text" v-if="items.length > 0">
                    {{ $t("string.resetIn") }} {{ countdown }}
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div
                  class="coin-panel d-flex justify-end align-center text-h6"
                  v-if="items.length > 0"
                >
                  {{ this.currencyAmount }}
                  <v-img
                    :src="JSON.parse(items[0].Currency.images).icon.url"
                    max-width="30"
                    class="ms-2"
                  >
                  </v-img>
                </div>
                <div class="text-center">
                  <v-btn
                    color="yellow"
                    class="text-h6"
                    @click="
                      $router.push({
                        name: selectedTab.link,
                      })
                    "
                  >
                    {{ $t("string.go") }}
                  </v-btn>
                </div>
              </div>

              <!-- <div class="d-flex justify-space-between pa-3">
                <div>
                  <v-btn
                    icon
                    @click="previousPage()"
                    :disabled="currentPage == 1"
                  >
                    <v-img
                      :class="currentPage == 1 ? 'disabled-item-image' : null"
                      contain
                      max-width="40"
                      class="mx-auto"
                      :src="require('@/assets/island/ArrowButtonPre.png')"
                    ></v-img>
                  </v-btn>
                </div>
                <div class="text-stroke-md text-h5">
                  {{ $t("string.page") }} {{ currentPage }} / {{ totalPage }}
                </div>
                <div>
                  <v-btn
                    icon
                    @click="nextPage()"
                    :disabled="totalPage <= 1 || currentPage == totalPage"
                  >
                    <v-img
                      :class="
                        totalPage <= 1 || currentPage == totalPage
                          ? 'disabled-item-image'
                          : null
                      "
                      contain
                      max-width="40"
                      class="mx-auto"
                      :src="require('@/assets/island/ArrowButtonNex.png')"
                    ></v-img>
                  </v-btn>
                </div>
              </div> -->
              <div>
                <div v-if="api.isLoading || itemApi.isLoading" class="text-center pa-3">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <div v-else-if="items.length == 0 && itemApi.isLoading == false" class="text-center">
                  {{ $t("string.noItem") }}
                </div>
                <div v-for="(set, i) in slicedData" :key="i">
                  <v-row class="mx-3">
                    <v-col
                      class="px-1 py-3"
                      cols="3"
                      @click="
                        (eventStatus == 'UPCOMING' &&
                          selectedTab.key == 'event') ||
                        (avatar.charKey == 'mochi_v3' &&
                          item.Item.category != 'hat')
                          ? null
                          : selectItem(item)
                      "
                      v-for="(item, j) in set"
                      :key="j"
                    >
                      <Item
                        :imageUrl="JSON.parse(item.Item.images).icon.url"
                        :isTick="item.Inventory.quantity > 0"
                        :isSelected="selectedItem.key == item.Item.key"
                        :isShowPrice="item.Inventory.quantity == 0"
                        :price="item.Item.price"
                        :currency="JSON.parse(item.Currency.images).icon.url"
                        :isDisabled="
                          avatar.charKey == 'mochi_v3' &&
                          item.Item.category != 'hat'
                        "
                        :id="j == 0 ? 'TutHat' : ''"
                        :isLock="
                          eventStatus == 'UPCOMING' &&
                          selectedTab.key == 'event'
                        "
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div
                class="text-center mt-5"
                v-if="selectedItem && selectedItem.name"
              >
                {{ selectedItem.name }}
              </div>
            </div>

            <RewardDialog
              v-if="bought"
              :callbackClose="exitRwd"
              :showDialog="true"
              :rewards="[
                {
                  Item: {
                    images: JSON.parse(selectedItem.images),
                  },
                  quantity: 1,
                },
              ]"
            />
          </div>
          <v-card class="save-confirm" v-if="buyConfirmDialog">
            <div class="burst"></div>
            <div style="max-width: 480px; height: 100vh; margin: auto">
              <div
                class="d-flex flex-column align-center justify-center"
                style="min-height: calc(100vh - 100px) !important"
              >
                <AbstractAvatar
                  :avatar="JSON.stringify(this.avatar)"
                  :height="300"
                  :width="300"
                  class="mx-5"
                />
                <div
                  style="z-index: 1 !important"
                  class="d-flex flex-column align-center justify-center"
                >
                  <v-btn
                    large
                    color="yellow"
                    class="my-3 mx-3 text-h6 px-10"
                    min-width="100"
                    @click="buyConfirmDone"
                  >
                    {{ $t("action.done") }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-main>
      <AbstractChat
        v-if="tutorialChat && chatStart"
        X1="Hamochie"
        :chats="tutorialChat"
        :callback="callback"
      />
      <SkipTut />
    </v-app>
  </div>
  <!-- </transition> -->
</template>
  
  <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    coin: (state) => state.coin.data,
    tutorial: (state) => state.tutorial.data,
    countdown() {
      return this.remainingTime;
    },
  }),
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    Item: () =>
      import(
        /* webpackChunkName: "component-shop-item" */ "@/components/shop/Item.vue"
      ),
    RewardDialog: () =>
      import(
        /* webpackChunkName: "component-reward" */ "@/components/games/RewardDialog"
      ),
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    AbstractChat: () =>
      import(
        /* webpackChunkName: "component-abstract-chat" */ "@/components/tutorial/AbstractChat"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
  },
  data: () => ({
    newBuyer: false,
    bought: false,
    currentPage: 1,
    totalPage: 0,
    a: 0,
    b: 2,
    selectedItem: {},
    selectedItemInventory: {},
    studentAvatar: {},
    intraction: null,
    enterShop: false,
    buyConfirmDialog: false,
    purchased: false,
    selectedHatItem: null,
    selectedValue: null,
    selectedItemQty: null,
    changeHat: false,
    items: [],
    itemData: [],
    slicedData: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    currencyApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    itemApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    buyApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    avatarSaveApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    tutorialApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    student: [],
    buyItem: false,
    avatar: {},
    newAvatar: {},
    authData: [],
    isRerenderAvatar: false,
    saveAvatarHat: false,
    reFetchItem: false,
    chatStart: false,
    tutorialChat: null,
    tutExploreShopChats_1: [],
    tutExploreShopChats_2: [],
    tutExploreShopChats_3: [],
    navs: [],
    selectedTab: {},
    remainingTime: "",
    eventStatus: "",
    endTime: "",
    currency: [],
    currencyAmount: 0,
  }),
  watch: {
    selectedTab(newTab) {
      this.changeTab(newTab.key);
    },
  },
  created() {
    this.avatar = this.$_.cloneDeep(JSON.parse(this.auth.Player.avatar));
    this.studentAvatar = this.$_.cloneDeep(this.auth.Player.avatar);
    this.api.url =
      this.$api.servers.game +
      "/api/v2/" +
      this.$_getLocale() +
      "/game/shop/visit";
    this.itemApi.url = `${
      this.$api.servers.game
    }/api/v1/${this.$_getLocale()}/game/inventory/listByType`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.buyApi.callbackReset = () => {
      this.buyApi.isLoading = true;
      this.buyApi.isError = false;
      this.buyApi.error = null;
    };
    this.avatarSaveApi.callbackReset = () => {
      this.avatarSaveApi.isLoading = true;
      this.avatarSaveApi.isError = false;
      this.avatarSaveApi.error = null;
    };
    this.itemApi.callbackReset = () => {
      this.itemApi.isLoading = true;
      this.itemApi.isError = false;
      this.itemApi.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.buyApi.callbackError = (e) => {
      this.buyApi.isLoading = false;
      this.buyApi.isError = true;
      this.buyApi.error = e;
    };
    this.avatarSaveApi.callbackError = (e) => {
      this.avatarSaveApi.isLoading = false;
      this.avatarSaveApi.isError = true;
      this.avatarSaveApi.error = e;
    };
    this.itemApi.callbackError = (e) => {
      this.itemApi.isLoading = false;
      this.itemApi.isError = true;
      this.itemApi.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      var inventoryItems = resp.Product.filter(function (entry) {
        return entry.Inventory.quantity > 0;
      });
      if (inventoryItems.length == 0) {
        this.newBuyer = true;
      }
      this.authData = this.$_.clone(this.auth);
      this.api.isLoading = false;
      this.items = this.$_.filter(resp.Product, (o) => {
        return o.Item.setKey != null;
      });
      this.itemData = this.$_.groupBy(this.items, (o) => {
        return o.Item.setKey;
      });

      if (this.items.length > 0)
        this.getCurencyCount(this.items[0].Currency.key);

      if (
        (this.selectedTab.key == "event" ||
          (this.selectedTab.key == "monthly" &&
            this.items[0].Item.ShopItem[0].timestampEnd)) &&
        this.items.length > 0
      ) {
        this.eventStatus = this.status(
          this.items[0].Item.ShopItem[0].timestampStart
        );
        this.endTime =
          this.eventStatus == "UPCOMING"
            ? this.items[0].Item.ShopItem[0].timestampStart
            : this.items[0].Item.ShopItem[0].timestampEnd;
        this.updateCountdown();
        setInterval(() => {
          this.updateCountdown();
        }, 1000);
      }

      // this.selectItem(this.items[0])
      this.totalPage = Math.ceil(Object.keys(this.itemData).length / 2);
      if (parseInt(this.$route.query.page)) {
        this.setPage();
      } else {
        this.sliceItems();
      }

      // #tutExploreShop - shop tutorial starts
      if (
        this.tutorial.Ongoing == "tutExploreShop" &&
        this.tutorial.Step == 3
      ) {
        if (this.newBuyer) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreShop";
          tempTut.Step = 4;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreShopChats_1),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        } else {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreShop";
          tempTut.Step = 7;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(
            () => (
              (this.tutorialChat = this.tutExploreShopChats_2),
              (this.chatStart = true),
              (this.intraction = "pointer-events:all")
            ),
            800
          );
        }
      }
    };
    this.buyApi.callbackSuccess = (resp) => {
      this.$api.fetch(this.currencyApi);
      // this.$store.commit("updateCoin", {
      //   Currency: { quantity: resp.Currency.quantity },
      // });
      this.selectedItemInventory.quantity = resp.Item.quantity;
      this.buyApi.isLoading = false;
      this.$api.fetch(this.api);
      this.saveAvatar();
      this.bought = true;
      this.$intro().exit();
    };
    this.avatarSaveApi.callbackSuccess = (resp) => {
      this.authData.Player = resp.Player;
      this.$store.commit("updateAuth", this.authData);
      this.avatar = this.$_.cloneDeep(JSON.parse(resp.Player.avatar));
      this.avatarSaveApi.isLoading = false;
      this.studentAvatar = this.$_.cloneDeep(resp.Player.avatar);
    };
    this.itemApi.callbackSuccess = (resp) => {
      this.items = resp;
      this.itemApi.isLoading = false;
    };

    this.currencyApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/inventory";
    this.currencyApi.callbackReset = () => {
      this.currencyApi.isLoading = true;
      this.currencyApi.isError = false;
      this.currencyApi.error = null;
    };
    this.currencyApi.callbackError = (e) => {
      this.currencyApi.isLoading = false;
      this.currencyApi.isError = true;
      this.currencyApi.error = e;
    };
    this.currencyApi.callbackSuccess = (resp) => {
      this.currencyApi.isLoading = false;
      this.currency = resp;
      this.getCurencyCount(this.items[0].Currency.key);

      if (
        this.$route.query.tab &&
        this.navs.find((tab) => tab.key === this.$route.query.tab)
      ) {
        this.selectedTab = this.navs.find(
          (tab) => tab.key === this.$route.query.tab
        );
      } else {
        this.selectedTab = this.navs[0];
      }
    };
  },
  mounted() {
    this.navs = [
      {
        id: 1,
        name: this.$t("string.basicItems"),
        key: "basic",
        link: "PageExploreDailyGame",
      },

      {
        id: 2,
        name: this.$t("string.monthly"),
        key: "monthly",
        link: "PageExploreDailyGame",
      },
      {
        id: 3,
        name: this.$t("string.event"),
        key: "event",
        link: "PageExploreSeries",
      },
      {
        id: 4,
        name: this.$t("string.tower"),
        key: "tower",
        link: "PageExploreDailyGame",
      },
      {
        id: 5,
        name: this.$t("string.drawing"),
        key: "studio",
        link: "PageHomeStudio",
      },
    ];

    if (
      this.$route.query.tab &&
      this.navs.find((tab) => tab.key === this.$route.query.tab)
    ) {
      this.selectedTab = this.navs.find(
        (tab) => tab.key === this.$route.query.tab
      );
    } else {
      this.selectedTab = this.navs[0];
    }
    this.api.params = {
      type: this.selectedTab.key,
    };
    this.$api.fetch(this.api);
    this.$api.fetch(this.currencyApi);
    this.tutExploreShopChats_1 = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_4_1"),
        sound: "sfx_chat_2",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_5"),
        sound: "sfx_chat_1",
      },
    ];
    this.tutExploreShopChats_2 = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_4_2"),
        sound: "sfx_chat_1",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_10"),
        sound: "sfx_chat_2",
      },
    ];
    this.tutExploreShopChats_3 = [
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_8"),
        sound: "sfx_chat_2",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_9"),
        sound: "sfx_chat_1",
      },
      {
        citizen: "Hamochi",
        chat: this.$t("tutorial.exploreShop.dialog_10"),
        sound: "sfx_chat_3",
      },
    ];
  },
  methods: {
    visitWardrobe() {
      this.$router.push({
        name: "PageHomeWardrobe",
      });
    },
    getCurencyCount(key) {
      var coins = null;
      coins = this.currency.filter(function (entry) {
        return entry.Item.key == key;
      });
      if (coins[0]) {
        this.currencyAmount = coins[0].Inventory.quantity;
      } else {
        this.currencyAmount = 0;
      }
    },
    status(timestampStart) {
      if (this.$moment().isAfter(timestampStart)) {
        return "ONGOING";
      } else {
        return "UPCOMING";
      }
    },
    updateCountdown() {
      const end = this.$moment(this.endTime);
      const now = this.$moment();
      const duration = this.$moment.duration(end.diff(now));
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      this.remainingTime = `${days}D ${hours}H ${minutes}M ${seconds}S`;
    },
    changeTab(type) {
      this.selectedItemInventory = null;
      this.selectedItem = {};
      this.items = [];
      this.slicedData = [];
      this.api.params = {
        type: type,
      };
      this.$api.fetch(this.api);
    },
    buyConfirmDone() {
      this.buyConfirmDialog = false;
      // #tutExploreShop - shop tutorial starts
      if (
        this.tutorial.Ongoing == "tutExploreShop" &&
        this.tutorial.Step == 6
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreShop";
        tempTut.Step = 7;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(
          () => (
            (this.tutorialChat = this.tutExploreShopChats_3),
            (this.chatStart = true),
            (this.intraction = "pointer-events:all")
          ),
          800
        );
      }
    },
    exit() {
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
    exitRwd() {
      this.bought = false;
      this.buyConfirmDialog = true;
    },
    buyConfirm() {
      this.buyApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/buy";
      this.buyApi.params = this.$_.clone({
        quantity: 1,
        itemId: this.selectedItem.id,
      });
      this.$api.fetch(this.buyApi);
    },
    selectItem(item) {
      this.$intro().exit();
      if (!this.selectedItem.key != item.Item.key) {
        this.selectedItem = item.Item;
        this.selectedItemInventory = item.Inventory;
        var avatar = this.$_.cloneDeep(this.avatar);
        avatar[this.selectedItem.category] = this.selectedItem.key;
        this.newAvatar = avatar;
      }
      // #tutExploreShop - after the shop tutorial intrduction finish it will triger introjs to gide user to click menu button
      if (
        this.tutorial.Ongoing == "tutExploreShop" &&
        this.tutorial.Step == 5
      ) {
        let tempTut = this.$_.cloneDeep(this.tutorial);
        tempTut.Ongoing = "tutExploreShop";
        tempTut.Step = 6;
        this.$store.commit("updateTutorial", tempTut);

        this.intraction = "pointer-events: none;";
        setTimeout(() => {
          this.$intro().exit();
          this.$intro()
            .setOptions({
              exitOnOverlayClick: false,
              showButtons: false,
              showBullets: false,
              steps: [
                {
                  element: document.querySelector("#BuyNow"),
                  intro:
                    '<img src="' +
                    require("@/assets/tutorial/chat_2.png") +
                    '">' +
                    this.$t("tutorial.exploreShop.dialog_7"),
                },
              ],
            })
            .start();
          this.intraction = "pointer-events: all;";
          let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
          audio.volume = this.settings.audio.sfx * this.settings.audio.master;
          audio.play();
        }, 1000);
      }
    },
    sliceItems() {
      this.slicedData = Object.keys(this.itemData)
        .slice(this.a, this.b)
        .reduce((result, key) => {
          result[key] = this.itemData[key];
          return result;
        }, {});
    },
    nextPage() {
      this.currentPage = this.currentPage + 1;
      this.a = this.a + 2;
      this.b = this.b + 2;
      this.sliceItems();
    },
    previousPage() {
      this.currentPage = this.currentPage - 1;
      this.a = this.a - 2;
      this.b = this.b - 2;
      this.sliceItems();
    },
    setPage() {
      this.currentPage = parseInt(this.$route.query.page);
      this.a = this.currentPage * 2 - 2;
      this.b = this.a + 2;
      this.sliceItems();
    },
    saveAvatar() {
      this.avatarSaveApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/avatar/save";
      this.avatarSaveApi.params = this.$_.clone({
        avatar: JSON.stringify(this.newAvatar),
      });
      this.$api.fetch(this.avatarSaveApi);
    },
    callback() {
      this.chatStart = false;
      // #tutExploreShop - after the shop tutorial intrduction finish it will triger introjs to gide user to click menu button
      if (this.tutorial.Ongoing == "tutExploreShop") {
        if (this.tutorial.Step == 4) {
          let tempTut = this.$_.cloneDeep(this.tutorial);
          tempTut.Ongoing = "tutExploreShop";
          tempTut.Step = 5;
          this.$store.commit("updateTutorial", tempTut);

          this.intraction = "pointer-events: none;";
          setTimeout(() => {
            this.$intro().exit();
            this.$intro()
              .setOptions({
                exitOnOverlayClick: false,
                showButtons: false,
                showBullets: false,
                steps: [
                  {
                    element: document.querySelector("#TutHat"),
                    intro:
                      '<img src="' +
                      require("@/assets/tutorial/chat_1.png") +
                      '">' +
                      this.$t("tutorial.exploreShop.dialog_6"),
                  },
                ],
              })
              .start();
            this.intraction = "pointer-events: all;";
            let audio = new Audio(require("@/assets/sounds/sfx_chat_1.mp3"));
            audio.volume = this.settings.audio.sfx * this.settings.audio.master;
            audio.play();
          }, 1000);
        } else if (this.tutorial.Step == 7) {
          this.tutorialApi.url =
            this.$api.servers.game +
            "/api/v1/" +
            this.$_getLocale() +
            "/game/tutorial/complete";
          this.tutorialApi.callbackReset = () => {
            this.tutorialApi.isLoading = true;
            this.tutorialApi.isError = false;
            this.tutorialApi.error = null;
          };
          this.tutorialApi.callbackError = (e) => {
            this.tutorialApi.isLoading = false;
            this.tutorialApi.isError = true;
            this.tutorialApi.error = e;
          };
          this.tutorialApi.callbackSuccess = (resp) => {
            this.tutorialApi.isLoading = false;
            this.$store.commit("updateTutorial", resp);

            // this.$router.push({
            //   name: "PageExploreLanding",
            // });
          };
          if (this.tutorial.Ongoing == "tutExploreShop") {
            this.tutorialApi.params = {
              key: "tutExploreShop",
            };
          }
          this.$api.fetch(this.tutorialApi);
        }
      }
    },
  },
};
</script>
  
  <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.coin-wrapper {
  display: flex;
  align-items: center;
}
.coin-panel {
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  z-index: 1;
  min-width: 120px;
  margin: 10px;
  right: 0px;
  padding: 2px 10px; /* remove this */
  background-color: #fff;
  text-align: right;
}
.burst {
  width: 100vw;
  height: 100vh;
  background: url("../../../assets/island/Burst.png") center bottom !important;
  background-size: cover !important;
  position: absolute;
  opacity: 0.6;
  z-index: 0;
}
.text-border {
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  z-index: 3;
}
.item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 10px;
  margin-top: 10px;
}

.low-item-bar {
  background: rgb(255, 255, 255);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px;
  margin-top: 10px;
}
.avatar {
  height: 140px;
  overflow: hidden;
}
.crop {
  width: 70px;
  height: 30px;
  overflow: hidden;
}

.hat {
  width: 100px;
  height: 100px;
  margin: -10px 0 0 -10px;
}
.avatar-hat {
  position: absolute;
}

@media (max-height: 500px) {
  #shop {
    background-size: auto 500px !important;
  }
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-md {
  color: #fff;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.items-panel {
  border: #caad87 5px solid;
  background-color: #f7efe3;
  padding-bottom: 30px !important;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 60px 0 0px 0;
  /* background-color: #ffb8b8; */
  position: relative;
}

.main-body {
  /* background-color: #ffb8b8; */
  background-color: rgba(255, 71, 123, 0.3);
}

.save-confirm {
  position: fixed;
  background-color: #ffb8b8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

#shop {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.buy-confirm {
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

#shop-app {
  background: transparent !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.shop-plot {
  background: rgba(0, 0, 0, 0.2);
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 15px;
  border-radius: 10px;
}

.welcome-enter-active {
  animation: welcome-in 0.5s;
}
.welcome-leave-active {
  animation: welcome-out 0.5s reverse;
}
@keyframes welcome-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes welcome-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.low_graphics {
  background: none !important;
}

.low_island_plot {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 10px;
  /* padding: 20px !important; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_title_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  padding: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  margin: 20px;
}
.low_island_building_plot {
  background-color: rgb(246, 246, 246) !important;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}
.low_island_coin_plot {
  background-color: rgb(246, 246, 246) !important;
  width: 100%;
  margin-right: 10px;
  padding: 5px 2%;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.low_plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}
.plot {
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
}

.disabled-item-image {
  filter: brightness(0%) contrast(10%) !important;
}

.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  display: flex;
}

.tab-card {
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  text-shadow: #876a47 3px 0px 0px, #876a47 2.83487px 0.981584px 0px,
    #876a47 2.35766px 1.85511px 0px, #876a47 1.62091px 2.52441px 0px,
    #876a47 0.705713px 2.91581px 0px, #876a47 -0.287171px 2.98622px 0px,
    #876a47 -1.24844px 2.72789px 0px, #876a47 -2.07227px 2.16926px 0px,
    #876a47 -2.66798px 1.37182px 0px, #876a47 -2.96998px 0.42336px 0px,
    #876a47 -2.94502px -0.571704px 0px, #876a47 -2.59586px -1.50383px 0px,
    #876a47 -1.96093px -2.27041px 0px, #876a47 -1.11013px -2.78704px 0px,
    #876a47 -0.137119px -2.99686px 0px, #876a47 0.850987px -2.87677px 0px,
    #876a47 1.74541px -2.43999px 0px, #876a47 2.44769px -1.73459px 0px,
    #876a47 2.88051px -0.838247px 0px;
}

.shop-panel {
  z-index: 1;
  margin: 10px;
  left: 0px;
  position: absolute;
}

.selectedTab-tab-card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 5px 5px;
  width: calc(100% / 3);
  border: #68fffc 4px solid;
  background-color: #d3fdfc;
  text-align: center;
  color: #fff;
  text-shadow: #29a399 3px 0px 0px, #29a399 2.83487px 0.981584px 0px,
    #29a399 2.35766px 1.85511px 0px, #29a399 1.62091px 2.52441px 0px,
    #29a399 0.705713px 2.91581px 0px, #29a399 -0.287171px 2.98622px 0px,
    #29a399 -1.24844px 2.72789px 0px, #29a399 -2.07227px 2.16926px 0px,
    #29a399 -2.66798px 1.37182px 0px, #29a399 -2.96998px 0.42336px 0px,
    #29a399 -2.94502px -0.571704px 0px, #29a399 -2.59586px -1.50383px 0px,
    #29a399 -1.96093px -2.27041px 0px, #29a399 -1.11013px -2.78704px 0px,
    #29a399 -0.137119px -2.99686px 0px, #29a399 0.850987px -2.87677px 0px,
    #29a399 1.74541px -2.43999px 0px, #29a399 2.44769px -1.73459px 0px,
    #29a399 2.88051px -0.838247px 0px;
}
</style>