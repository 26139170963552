var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body",style:(_vm.intraction)},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),(_vm.avatarSaveApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.avatarSaveApi}}):_vm._e(),(_vm.buyApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.buyApi}}):_vm._e(),_c('v-container',{staticClass:"app-body"},[_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("title.shop"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit,"id":"TutExit"}})],1),_c('div',[_c('div',{staticClass:"shop-panel"},[_c('v-img',{staticClass:"ms-2 d-flex justify-center align-end text-center text-stroke-md",staticStyle:{"cursor":"pointer"},attrs:{"src":require('@/assets/explore/menu/wardrobe.png'),"max-width":"80"},on:{"click":function($event){return _vm.visitWardrobe()}}},[_vm._v(" "+_vm._s(_vm.$t("title.wardrobe"))+" ")])],1),_c('v-img',{staticClass:"d-flex align-center px-5 mx-auto",attrs:{"src":require('@/assets/explore/shop.png'),"contain":""}},[_c('AbstractAvatar',{staticClass:"mx-12",attrs:{"avatar":_vm.studentAvatar,"height":200,"width":200,"selectedItem":_vm.selectedItem}})],1),_c('div',{staticStyle:{"height":"50px","margin-top":"-50px","margin-bottom":"20px"}},[(
                _vm.selectedItemInventory && _vm.selectedItemInventory.quantity == 0
              )?_c('div',{staticClass:"pa-2 text-center"},[_c('v-btn',{staticClass:"text-h5 px-8",attrs:{"loading":_vm.buyApi.isLoading,"large":"","color":"yellow","id":"BuyNow"},on:{"click":_vm.buyConfirm}},[_vm._v(" "+_vm._s(_vm.$t("action.buy"))+" ")])],1):(
                _vm.selectedItemInventory && _vm.selectedItemInventory.quantity > 0
              )?_c('div',{staticClass:"pa-2 text-center"},[_c('v-chip',{staticClass:"text-h5 px-8 grey--text rounded-sm text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("string.own"))+" ")])],1):_vm._e()]),_c('v-carousel',{attrs:{"hide-delimiters":"","height":"60"},scopedSlots:_vm._u([{key:"prev",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","max-width":"40","src":require('@/assets/island/ArrowButtonPre.png')}})],1)]}},{key:"next",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","max-width":"40","src":require('@/assets/island/ArrowButtonNex.png')}})],1)]}}]),model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.navs),function(nav,i){return _c('v-carousel-item',{key:i,attrs:{"value":nav}},[_c('v-sheet',{staticClass:"tab-bar-card",attrs:{"tile":"","height":"100%"}},[_c('div',{staticClass:"text-h6 tab-card"},[_vm._v(_vm._s(nav.name))])])],1)}),1),_c('div',{staticClass:"items-panel pa-3 mt-2"},[_c('div',{staticClass:"coin-wrapper"},[(_vm.selectedTab.key == 'event' && _vm.items.length > 0)?_c('div',[(_vm.eventStatus == 'ONGOING')?_c('div',{staticClass:"text-center brown--text"},[_vm._v(" "+_vm._s(_vm.$t("string.closeIn"))+" "+_vm._s(_vm.countdown)+" ")]):(_vm.eventStatus == 'UPCOMING')?_c('div',{staticClass:"text-center brown--text"},[_vm._v(" "+_vm._s(_vm.$t("string.openIn"))+" "+_vm._s(_vm.countdown)+" ")]):_vm._e()]):_vm._e(),(
                  _vm.selectedTab.key == 'monthly' &&
                  _vm.items.length > 0 &&
                  _vm.items[0].Item.ShopItem[0].timestampEnd
                )?_c('div',[(_vm.items.length > 0)?_c('div',{staticClass:"text-center brown--text"},[_vm._v(" "+_vm._s(_vm.$t("string.resetIn"))+" "+_vm._s(_vm.countdown)+" ")]):_vm._e()]):_vm._e(),_c('v-spacer'),(_vm.items.length > 0)?_c('div',{staticClass:"coin-panel d-flex justify-end align-center text-h6"},[_vm._v(" "+_vm._s(this.currencyAmount)+" "),_c('v-img',{staticClass:"ms-2",attrs:{"src":JSON.parse(_vm.items[0].Currency.images).icon.url,"max-width":"30"}})],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-h6",attrs:{"color":"yellow"},on:{"click":function($event){return _vm.$router.push({
                      name: _vm.selectedTab.link,
                    })}}},[_vm._v(" "+_vm._s(_vm.$t("string.go"))+" ")])],1)],1),_c('div',[(_vm.api.isLoading || _vm.itemApi.isLoading)?_c('div',{staticClass:"text-center pa-3"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.items.length == 0 && _vm.itemApi.isLoading == false)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("string.noItem"))+" ")]):_vm._e(),_vm._l((_vm.slicedData),function(set,i){return _c('div',{key:i},[_c('v-row',{staticClass:"mx-3"},_vm._l((set),function(item,j){return _c('v-col',{key:j,staticClass:"px-1 py-3",attrs:{"cols":"3"},on:{"click":function($event){(_vm.eventStatus == 'UPCOMING' &&
                        _vm.selectedTab.key == 'event') ||
                      (_vm.avatar.charKey == 'mochi_v3' &&
                        item.Item.category != 'hat')
                        ? null
                        : _vm.selectItem(item)}}},[_c('Item',{attrs:{"imageUrl":JSON.parse(item.Item.images).icon.url,"isTick":item.Inventory.quantity > 0,"isSelected":_vm.selectedItem.key == item.Item.key,"isShowPrice":item.Inventory.quantity == 0,"price":item.Item.price,"currency":JSON.parse(item.Currency.images).icon.url,"isDisabled":_vm.avatar.charKey == 'mochi_v3' &&
                        item.Item.category != 'hat',"id":j == 0 ? 'TutHat' : '',"isLock":_vm.eventStatus == 'UPCOMING' &&
                        _vm.selectedTab.key == 'event'}})],1)}),1)],1)})],2),(_vm.selectedItem && _vm.selectedItem.name)?_c('div',{staticClass:"text-center mt-5"},[_vm._v(" "+_vm._s(_vm.selectedItem.name)+" ")]):_vm._e()]),(_vm.bought)?_c('RewardDialog',{attrs:{"callbackClose":_vm.exitRwd,"showDialog":true,"rewards":[
              {
                Item: {
                  images: JSON.parse(_vm.selectedItem.images),
                },
                quantity: 1,
              },
            ]}}):_vm._e()],1),(_vm.buyConfirmDialog)?_c('v-card',{staticClass:"save-confirm"},[_c('div',{staticClass:"burst"}),_c('div',{staticStyle:{"max-width":"480px","height":"100vh","margin":"auto"}},[_c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"min-height":"calc(100vh - 100px) !important"}},[_c('AbstractAvatar',{staticClass:"mx-5",attrs:{"avatar":JSON.stringify(this.avatar),"height":300,"width":300}}),_c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"z-index":"1 !important"}},[_c('v-btn',{staticClass:"my-3 mx-3 text-h6 px-10",attrs:{"large":"","color":"yellow","min-width":"100"},on:{"click":_vm.buyConfirmDone}},[_vm._v(" "+_vm._s(_vm.$t("action.done"))+" ")])],1)],1)])]):_vm._e()],1)],1),(_vm.tutorialChat && _vm.chatStart)?_c('AbstractChat',{attrs:{"X1":"Hamochie","chats":_vm.tutorialChat,"callback":_vm.callback}}):_vm._e(),_c('SkipTut')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }